<template>
  <div>
    <!-- <b-form-textarea
      @paste="handlePaste"
    /> -->

    <b-card title="密碼規則">
      <div class="mb-50">
        <b-row>
          <b-col
            cols="12"
            md="3"
            sm="4"
          >
            <label>密碼長度</label>
          </b-col>

          <b-col
            cols="12"
            md="7"
            sm="8"
          >
            <vue-slider
              v-model="size"
              class="mb-2"
              :tooltip="'always'"
            />
          </b-col>
        </b-row>
      </div>

      <b-row class="mb-1">
        <b-col
          cols="12"
          md="3"
          sm="4"
        >
          <label>字母</label>
        </b-col>

        <b-col
          cols="12"
          md="9"
          sm="8"
        >
          <b-form-radio-group
            v-model="lettersType"
            :options="lettersOptions"
            name="letters-type"
          />
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col
          cols="12"
          md="3"
          sm="4"
        >
          <label>數字和符號</label>
        </b-col>

        <b-col
          cols="12"
          md="9"
          sm="8"
        >
          <b-form-radio-group
            v-model="numbersignType"
            :options="numbersignOptions"
            name="umbersign-type"
          />
        </b-col>
      </b-row>

      <div class="text-right">
        <b-button
          variant="outline-secondary"
          size="sm"
          class="mr-1"
          @click="() => tableData = []"
        >
          一鍵清空
        </b-button>

        <b-button
          variant="primary"
          size="sm"
          @click="generate"
        >
          生成
        </b-button>
      </div>
    </b-card>

    <b-card
      title="密碼列表"
      no-body
    >
      <b-table
        ref="selectableTable"
        responsive
        show-empty
        :fields="[
          { label: '序號', key: 'index' },
          { label: '密碼', key: 'password' },
          { label: '選擇', key: 'actions' }
        ]"
        :items="tableData"
        :sort-desc="false"
        hover
        striped
        class="mt-1"
      >
        <!-- 查無資料 -->
        <template #empty>
          <div class="text-center my-5 animate__animated animate__fadeIn">
            <b-img
              :src="$store.state.app.notFoundImg"
              fluid
              width="480"
              alt="查無資料"
            />
          </div>
        </template>

        <!-- 欄位: 編號 -->
        <template #cell(index)="data">
          <div class="table-col">
            <span>{{ tableData.length - data.index }}</span>
          </div>
        </template>

        <!-- 欄位: 密碼 -->
        <template #cell(password)="data">
          <div
            class="font-weight-bolder p-50"
            @click="onSubmitCopy(data.item)"
          >
            <span class="m-0">
              {{ data.item.password }}
            </span>
          </div>
        </template>

        <!-- 欄位: 動作 -->
        <template #cell(actions)="data">
          <div class="d-flex mb-50">
            <div
              class="actions-link-btn mr-25"
              @click="onSubmitCopy(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="複製"
                src="/admin/images/table/copy.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>

            <div
              class="actions-link-btn mr-25"
              @click="onSubmitDelete(data.index)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="移除"
                src="/admin/images/table/close.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>
          </div>
        </template>
      </b-table>
    </b-card>

    <!-- <b-avatar
      size="42"
      src="/admin/images/todo/items/completed.svg"
      class="badge-minimal mr-75 border"
      badge
      variant="light-secondary"
    >
      <template #badge>
        <span
          class="bullet filter-dot"
          :style="{'--text-color':`${textColor}`}"
        />
      </template>
    </b-avatar> -->
  </div>
</template>

<script>
import { ref } from '@vue/composition-api' // , computed
import {
  BCard, BFormRadioGroup, BButton, BRow, BCol, BTable, BImg, VBTooltip, // BFormTextarea,
  // BFormInput, BAvatar,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import store from '@/store'
import router from '@/router'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    // BAvatar,
    BCard,
    BTable,
    BFormRadioGroup,
    // BFormInput,
    BButton,
    BRow,
    BCol,
    BImg,
    VueSlider,
    // BFormTextarea,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      title: router.currentRoute.meta.pageTitle,
      randomGenerator: null,
      size: 16,
      characters: 'a-z,A-Z,0-9,#',
      lettersType: 'a-z,A-Z',
      lettersOptions: [
        { text: '兩者(aBcD)', value: 'a-z,A-Z' },
        { text: '小寫字母(abcd)', value: 'a-z' },
        { text: '大寫字母(ABCD)', value: 'A-Z' },
      ],
      numbersignType: '0-9',
      numbersignOptions: [
        { text: '兩者(1@3$)', value: '0-9,#' },
        { text: '數字(1234)', value: '0-9' },
        { text: '符號(@#$)', value: '#' },
      ],
      // textColor: '#f00',
    }
  },
  methods: {
    // 亂數產密碼
    generate() {
      if (this.tableData.length >= 20) {
        this.useAlertToast(false, '密碼儲存已達上限')
        return
      }
      this.characters = `${this.lettersType},${this.numbersignType}`
      const charactersArray = this.characters.split(',')
      let CharacterSet = ''
      let randpassword = ''

      if (charactersArray.indexOf('a-z') >= 0) {
        CharacterSet += 'abcdefghijklmnopqrstuvwxyz'
      }
      if (charactersArray.indexOf('A-Z') >= 0) {
        CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      }
      if (charactersArray.indexOf('0-9') >= 0) {
        CharacterSet += '0123456789'
      }
      if (charactersArray.indexOf('#') >= 0) {
        CharacterSet += '![]{}()%&*$#^<>~@|'
      }

      // const pwLength = Math.random() * (this.size[1] - this.size[0]) + this.size[0]

      for (let i = 0; i < this.size; i += 1) {
        randpassword += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length))
      }
      this.randomGenerator = randpassword
      // if (this.tableData.length === 0) {
      //   this.tableData.push({
      //     password: randpassword,
      //     visible: true,
      //   })
      //   return
      // }
      this.tableData.unshift({
        password: randpassword,
        visible: true,
      })
      // this.$emit('call-back-data', this.randomGenerator)
    },

    // (複製)密碼
    onSubmitCopy(item) {
      const newPassword = item.password
      navigator.clipboard.writeText(newPassword)
        .then(() => {
          this.useAlertToast(true, '密碼已複製')
        })
        .catch(err => {
          this.useAlertToast(true, err)
        })
    },

    // (刪除)密碼
    onSubmitDelete(index) {
      this.tableData.splice(index, 1)
    },

    // 長度最小限制
    changeSize() {
      if (this.size < 0) this.size = 1
      if (this.size > 100) this.size = 100
    },
  },
  setup() {
    const tableData = ref([])

    const {
      useAlertToast,
    } = useAlert()

    return {
      tableData,
      useAlertToast,

      // handlePaste,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-slider.scss';

.custom-control-inline {
  margin-bottom: 1rem;
}

.text-box {
  color: var(--text-color);
}

.badge-minimal {
  position: relative;
}
// .filter-dot {
//   position: absolute;
//   // margin: -2px;
//   top: 0;
//   right: 0;
//   left: 0;
//   bottom: 0;
//   height: 9px;
//   width: 9px;
//   background-color: var(--text-color);
//   // background:radial-gradient(circle closest-side at center,var(--text-color), darken(var(--text-color), 10%));*/
//   // box-shadow: 0 0 16px 3px var(--text-color);
// }
</style>
